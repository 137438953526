/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-07",
    versionChannel: "nightly",
    buildDate: "2024-02-07T00:15:08.763Z",
    commitHash: "95f570107240a4eae9ff7584549223694eb96358",
};
